import T from "i18n-react";
import React, {useCallback} from "react";
import {analyseService} from "../../../services/analyse-service";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {Button, Card} from "react-bootstrap-v5";
import {UserRole} from "../../../utils/enums/user-role";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {alertActions} from "../../../redux/alert/actions-alert";
import {laboratoryActions} from "../../../redux/laboratory/actions-laboratory";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {analyseActions} from "../../../redux/analyse/actions-analyse";
import {connect} from "react-redux";
import RemoteTable from "../../../components/table/remote-table";

const TableAnalysesList = ({className, searchText, showToast, currentUser, showConfirmDialog, progress, showEditAnalyseModal, analysisDeleted}) => {

    let columns = [
        {
            dataField: T.translate('table.fields.analyse.category').toString(),
            text: '', //Colonne fantôme
            sort: false
        },
        {
            dataField: T.translate('table.fields.analyse.analyseName').toString(),
            text: T.translate('table.columns.analyse.name').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.method').toString(),
            text: T.translate('table.columns.method').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.unit').toString(),
            text: T.translate('table.columns.unit').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.laboratoryName').toString(),
            text: T.translate('table.columns.analyse.mainLaboratory').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.price').toString(),
            text: `${T.translate('table.columns.price')} ${!!currentUser.currencySymbol ? '(' + currentUser.currencySymbol + ')' : ''}`,
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.delay').toString(),
            text: T.translate('table.columns.analyse.delay').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.quantity').toString(),
            text: T.translate('table.columns.analyse.quantity').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString(),
            sort: false
        },
    ];

    if (currentUser.role === UserRole.SUPER_ADMINISTRATOR) {
        columns = [
            {
                dataField: T.translate('table.fields.analyse.company').toString(),
                text: T.translate('table.columns.company'),
                sort: true
            },
            ...columns
        ]
    }

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {

        const deleteAnalysis = async analysis => {
            try {
                await analyseService.deleteAnalysis(analysis.analysisId);
                analysisDeleted();
                showToast(T.translate('alert.successTitle'), T.translate('analyse.successDeleteAnalyse', {analysisName: analysis.analysisInfo.name}), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteAnalysis = async (e, analysis) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                analysis.numberOfProductsUsage > 0 || analysis.numberOfLotsUsage > 0 ? T.translate('dialog.confirmDeleteAnalysisUsedByProductAndLot', {
                        analysisName: analysis.analysisInfo.name,
                        countProducts: analysis.numberOfProductsUsage,
                        countLots: analysis.numberOfLotsUsage
                    })
                    : T.translate('dialog.confirmDeleteAnalysis', {analysisName: analysis.analysisInfo.name}),
                () => {
                },
                () => deleteAnalysis(analysis))
        }

        const handleEditAnalysis = (e, analysis) => {
            showEditAnalyseModal(analysis.analysisId)
        }

        const completeElements = allElements => {
            return allElements.map(element => {
                return {
                    ...element,
                    id: element.analysisId,
                    actions: (
                        <div className="actions">
                            <Button size="sm" className={`edit ${(currentUser.role === UserRole.VISITOR) ? "d-none" : ""}`}
                                    onClick={e => handleEditAnalysis(e, element)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                            <Button size="sm"
                                    className={`delete ms-1 ${(currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR) ? "" : "d-none"}`}
                                    onClick={e => handleDeleteAnalysis(e, element)}>
                                <FontAwesomeIcon icon="times"/>
                            </Button>
                        </div>
                    )
                }
            })
        }

        const data = await analyseService.getAll(page, size, sortedOrder, sortedField, searchText);
        const elements = completeElements(data.content);
        return {
            ...data,
            elements: elements
        };
    }, [showToast, showConfirmDialog, currentUser, showEditAnalyseModal, analysisDeleted]);

    return (
        <Card className={className}>
            <RemoteTable id="table-analysis-list"
                         defaultSortedField={T.translate('table.fields.analyse.analyseName')}
                         columns={columns} onFetchData={onFetchData} reload={progress} searchText={searchText}
                         groupByName={T.translate('table.fields.analyse.category').toString()}
                         groupById={T.translate('table.fields.analyse.categoryId')}/>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.analyseReducer.progress
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        laboratoryDeleted: () => dispatch(laboratoryActions.laboratoryDeleted()),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        showCreateAnalyseModal: () => dispatch(analyseActions.analyseCreate()),
        showEditAnalyseModal: (analysisId) => dispatch(analyseActions.analyseEdit(analysisId)),
        analysisDeleted: () => dispatch(analyseActions.analyseDeleted())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAnalysesList);