export const ANALYSE_SHOW_CREATE_MODAL = "ANALYSE_SHOW_CREATE_MODAL";
export const ANALYSE_SHOW_EDIT_MODAL = "ANALYSE_SHOW_EDIT_MODAL";
export const ANALYSE_HIDE_CREATE_OR_EDIT_MODAL = "ANALYSE_HIDE_CREATE_OR_EDIT_MODAL";
export const ANALYSE_SHOW_EDIT_REFERENCES_MODAL = "ANALYSE_SHOW_EDIT_REFERENCES_MODAL";
export const ANALYSE_EDIT_REFERENCES_PREVIOUS_MODAL = "ANALYSE_EDIT_REFERENCES_PREVIOUS_MODAL";
export const ANALYSE_HIDE_EDIT_REFERENCES_MODAL = "ANALYSE_HIDE_EDIT_REFERENCES_MODAL";
export const ANALYSE_SHOW_IMPORT_MODAL = "ANALYSE_SHOW_IMPORT_MODAL";
export const ANALYSE_HIDE_IMPORT_MODAL = "ANALYSE_HIDE_IMPORT_MODAL";
export const ANALYSE_DELETED = "ANALYSE_DELETED";
export const ANALYSE_LABORATORY_DELETED = "ANALYSE_LABORATORY_DELETED";
