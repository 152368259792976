import {
    LOT_ADD_ANALYSIS,
    LOT_CANCEL_TRANSFER,
    LOT_CONTROL_CHANGE_DISPLAY_ORDER,
    LOT_CONTROL_DELETED,
    LOT_DELETED,
    LOT_EDIT_LOT,
    LOT_HIDE_CREATE_MODAL,
    LOT_INCREMENT_SELECTED_ANALYSIS,
    LOT_SAVE_SEARCH_TEXT,
    LOT_SHOW_CREATE_MODAL,
    LOT_UPDATE_SELECTED_ANALYSIS
} from "./type";

const lotCreate = () => {
    return {
        type: LOT_SHOW_CREATE_MODAL
    }
}

const lotDeleted = () => {
    return {
        type: LOT_DELETED
    }
}

const hideCreateModal = () => {
    return {
        type: LOT_HIDE_CREATE_MODAL
    }
}

const addAnalysisToLot = () => {
    return {
        type: LOT_ADD_ANALYSIS
    }
}
const lotControlEdited = () => {
    return {
        type: LOT_EDIT_LOT
    }
}

const updateSelectedAnalysisNumber = (selectedAnalysisNumber) => {
    return {
        type: LOT_UPDATE_SELECTED_ANALYSIS,
        payload: selectedAnalysisNumber
    }
}

const incrementSelectedAnalysisNumber = (checked) => {
    return {
        type: LOT_INCREMENT_SELECTED_ANALYSIS,
        payload: checked
    }
}

const lotControlDeleted = () => {
    return {
        type: LOT_CONTROL_DELETED
    }
}

const lotControlChangeDisplayOrder = () => {
    return {
        type: LOT_CONTROL_CHANGE_DISPLAY_ORDER
    }
}

const lotCancelTransfer = () => {
    return {
        type: LOT_CANCEL_TRANSFER
    }
}

const saveSearchText = (text) => {
    return {
        type: LOT_SAVE_SEARCH_TEXT,
        payload: text
    }
}

export const lotActions = {
    lotCreate,
    lotDeleted,
    lotControlEdited,
    hideCreateModal,
    addAnalysisToLot,
    updateSelectedAnalysisNumber,
    incrementSelectedAnalysisNumber,
    lotControlDeleted,
    lotControlChangeDisplayOrder,
    lotCancelTransfer,
    saveSearchText
};