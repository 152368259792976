import React, {useState} from "react";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import Header from "../../components/header/header";
import {UserRole} from "../../utils/enums/user-role";
import {Badge, Button, Tab, Tabs} from "react-bootstrap-v5";
import T from "i18n-react";
import {connect} from "react-redux";
import {analyseActions} from "../../redux/analyse/actions-analyse";
import {useHistory} from "react-router-dom";
import {Routes} from "../../router/router-constants";
import TableAnalysesList from "./components/table-analysis-list";
import HeaderTextLabel from "../../components/header/header-text-label";
import TableAnalysesListInProgress from "./components/table-analyses-list-in-progress";
import {StatusLotClassname} from "../../utils/enums/status-lot";
import SearchInput from "../../components/input-label/search-input";

const AnalysesPage = ({currentUserRole, showCreateAnalyseModal, showImportModal, nbOfAnalysisInProgress}) => {

    const [searchText, setSearchText] = useState('');
    const [activeTab, setActiveTab] = useState('analysesInProgress'); // État pour stocker l'onglet actif

    const history = useHistory();

    const navigateToCategoriesPage = () => {
        history.push(Routes.CATEGORIES_ANALYSES_PAGE);
    }

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
        }
    }


    const headerButtons = currentUserRole !== UserRole.VISITOR && (
        <React.Fragment>
            {activeTab === 'catalog' && (
                <React.Fragment>
                    <Button variant="secondary" className="px-4 mb-2 mb-sm-0 me-3" onClick={navigateToCategoriesPage}>
                        {T.translate('analyse.button.manageCategories')}
                    </Button>
                    <Button variant="success" className="px-4 mb-2 mb-sm-0" onClick={showCreateAnalyseModal}>
                        {T.translate('analyse.button.createAnalyse')}
                    </Button>
                </React.Fragment>
            )}
            {activeTab === 'analysesInProgress' &&
                <Button variant="success" className="px-4 mb-2 mb-sm-0" onClick={showImportModal}>
                    {T.translate('analyse.button.importResult')}
                </Button>
            }
        </React.Fragment>
    );

    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.analysis')}>
            <Header>
                <HeaderTextLabel className="me-4" isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.analyses')}</h1>
                </HeaderTextLabel>
            </Header>
            <Header button={headerButtons}>
                <div className="search-container">
                    <SearchInput onChange={handleSearchChange}/>
                </div>
            </Header>
            <Tabs id="analysis-list-tab" className="analysis-list-tab pt-2 px-4" defaultActiveKey="analysesInProgress" activeKey={activeTab}
                  onSelect={(selectedTab) => setActiveTab(selectedTab)}>
                <Tab eventKey="analysesInProgress" title={
                    <React.Fragment>
                        <Badge className={`d-inline me-2 ${StatusLotClassname.IN_PROGRESS}`} pill={true}>{nbOfAnalysisInProgress}</Badge>
                        {T.translate('laboratory.tabs.analysesInProgress')}
                    </React.Fragment>
                }>
                    <Tab.Content>
                        <TableAnalysesListInProgress className="mx-3" searchText={searchText}/>
                    </Tab.Content>
                </Tab>
                <Tab eventKey="catalog" title={T.translate('laboratory.tabs.catalog')}>
                    <Tab.Content>
                        <TableAnalysesList className="mx-3" searchText={searchText}/>
                    </Tab.Content>
                </Tab>
            </Tabs>
        </PageWithMenuTemplate>
    )
}

const mapStateToProps = state => {
    return {
        currentUserRole: state.authReducer.currentUser.role,
        nbOfAnalysisInProgress: state.laboratoryReducer.nbOfAnalysisInProgress
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCreateAnalyseModal: () => dispatch(analyseActions.analyseCreate()),
        showImportModal: () => dispatch(analyseActions.showImportModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalysesPage);
