import Router from './router/router';
import store from "./redux/store";
import ToastAlertList from "./components/toast-alert/toast-alert-list";
import {Provider} from "react-redux";
import React, {useEffect, useState} from "react";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import ConfirmDialog from "./components/confirm-dialog/confirm-dialog";
import CreateCompanyModal from "./pages/companies-page/components/create-or-edit-company-modal";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import CreateOrEditAnalyseModal from "./pages/analyses-page/components/create-or-edit-analyse-modal";
import EditAnalyseReferencesModal from "./pages/analyses-page/components/edit-references-modal";
import CreateOrEditDocumentModal from "./pages/documents-page/components/create-or-edit-document-modal";
import I18NController from "./utils/i18n/i18n-controller";
import ImportModal from "./pages/analyses-page/components/import-modal";
import FormatModal from "./pages/laboratory-details-page/components/format-modal";

library.add(fas);

I18NController.getInstance().init();

const App = () => {
    const [, updateState] = useState();

    useEffect(() => {
        const forceUpdate = () => updateState({});

        I18NController.getInstance().setOnLangChanged(forceUpdate);
        forceUpdate();
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <Router/>
                <ConfirmDialog/>
                <CreateCompanyModal/>
                <CreateOrEditAnalyseModal/>
                <EditAnalyseReferencesModal/>
                <ImportModal/>
                <FormatModal/>
                <CreateOrEditDocumentModal/>
                <div className="toast-container position-fixed bottom-0 end-0 pe-2 pb-2">
                    <ToastAlertList/>
                </div>
            </Provider>
        </DndProvider>
    );
};

export default App;
