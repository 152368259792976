import React, {useCallback, useEffect, useRef, useState} from 'react';
import T from "i18n-react";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap-v5";
import InputLabel from "../../../components/input-label/input-label";
import TextareaLabel from "../../../components/input-label/textarea-label";
import CustomModal from "../../../components/custom-modal/custom-modal";
import Validator from "../../../utils/validators/validator";
import {companyService} from "../../../services/company-service";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {companyActions} from "../../../redux/company/actions-company";
import CompanyDto from "../../../models/dto/company-dto";
import AdminAccountDto from "../../../models/dto/admin-account-dto";
import CompanyAndAdminAccountDto from "../../../models/dto/company-and-admin-account-dto";
import {UserRole} from "../../../utils/enums/user-role";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import {FileType} from "../../../components/input-label/file-type";
import {documentService} from "../../../services/document-service";
import SelectLabel from "../../../components/input-label/select-label";
import {i18nService} from "../../../services/i18n-service";
import {authActions} from "../../../redux/auth/actions-auth";
import CheckLabel from "../../../components/input-label/check-label";
import {DocumentType} from "../../../utils/enums/document-type";

const CreateOrEditCompanyModal = ({companyReducer, currentUser, showToast, hideCreateOrEditModal, updateCurrentUserCurrency}) => {

    const adminEmailField = "email";
    const adminPhoneField = "administrorAccount.phoneNumber";
    const companyPhoneField = `${companyReducer.isCreationMode ? "company." : ""}companyContactDetails.phoneNumber`;
    const billingContactPhoneField = `${companyReducer.isCreationMode ? "company." : ""}billingContactDetails.phoneNumber`;
    const reportContactPhoneField = `${companyReducer.isCreationMode ? "company." : ""}reportContactDetails.phoneNumber`;

    const [isLoading, setLoading] = useState(false);

    const [company, setCompany] = useState({});
    const [adminAccount, setAdminAccount] = useState({});

    const imageCropperInputRef = useRef();
    const [cropper, setCropper] = useState();
    const [cropperImage, setCropperImage] = useState('');

    const [languages, setLanguages] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        const getLanguages = async () => {
            const availableLanguages = await i18nService.getAllLanguages();
            setLanguages(availableLanguages);
            if (!availableLanguages.isEmpty) {
                setAdminAccount(prevState => ({
                    ...prevState,
                    language: !prevState.language ? availableLanguages[0].localeCode : prevState.language,
                }));
            }
        }

        if (companyReducer.showCreateOrEditModal) {
            getLanguages();
        }
    }, [companyReducer.showCreateOrEditModal]);

    useEffect(() => {
        const getCurrencies = async () => {
            const availableCurrencies = await i18nService.getAllCurrencies();
            setCurrencies(availableCurrencies);
            if (!availableCurrencies.isEmpty) {
                setCompany(prevState => ({
                    ...prevState,
                    currency: !prevState.currency ? availableCurrencies[0].currencyCode : prevState.currency,
                }));
            }
        }

        if (companyReducer.showCreateOrEditModal) {
            getCurrencies();
        }
    }, [companyReducer.showCreateOrEditModal]);

    const onChangeImage = (e) => {
        e.preventDefault();

        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (!!files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                setCropperImage(reader.result);
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const initialStateInputErrors = {
        companyName: '',
        companyPhone: '',
        companyBillingContactEmail: '',
        companyBillingContactPhone: '',
        companyReportContactEmail: '',
        companyReportContactPhone: '',
        adminAccountFirstname: '',
        adminAccountLastname: '',
        adminAccountEmail: '',
        adminAccountPhone: '',
        adminAccountLanguage: '',
        companyCurrency: '',
        companyRcsNumber: '',
        companyEmail: ''
    };

    const [inputErrors, setInputErrors] = useState({...initialStateInputErrors});
    const [apiError, setApiError] = useState('');

    const resetErrors = () => {
        setInputErrors({...initialStateInputErrors});
        setApiError('');
    }

    const resetForm = useCallback(() => {
        setCompany(companyReducer.company ?? {});
        setAdminAccount({});
        setCropperImage('');
    }, [companyReducer.company]);

    const validate = () => {
        const companyNameError = Validator.validateRequired(company.name);
        const companyBillingContactEmailError = Validator.validateEmail(company.billingContactEmail);
        const companyReportContactEmailError = Validator.validateRequiredEmail(company.reportContactEmail);
        const adminAccountFirstnameError = companyReducer.isCreationMode ? Validator.validateRequired(adminAccount.firstname) : '';
        const adminAccountLastnameError = companyReducer.isCreationMode ? Validator.validateRequired(adminAccount.lastname) : '';
        const adminAccountEmailError = companyReducer.isCreationMode ? Validator.validateRequiredEmail(adminAccount.email) : '';
        const adminAccountLanguageError = companyReducer.isCreationMode ? Validator.validateRequired(adminAccount.language) : '';
        const companyCurrencyError = companyReducer.isCreationMode ? Validator.validateRequired(company.currency) : '';
        const companyRcsNumberError = Validator.validateRequired(company.rcsNumber);
        const companyEmailError = Validator.validateEmail(company.contactEmail);
        const isValid = !companyNameError && !companyBillingContactEmailError && !companyReportContactEmailError && !companyRcsNumberError
            && !adminAccountFirstnameError && !adminAccountLastnameError && !adminAccountEmailError && !companyEmailError && !adminAccountLanguageError && !companyCurrencyError;
        setInputErrors({
            ...inputErrors,
            companyName: T.translate(companyNameError).toString(),
            companyBillingContactEmail: T.translate(companyBillingContactEmailError).toString(),
            companyReportContactEmail: T.translate(companyReportContactEmailError).toString(),
            adminAccountFirstname: T.translate(adminAccountFirstnameError).toString(),
            adminAccountLastname: T.translate(adminAccountLastnameError).toString(),
            adminAccountEmail: T.translate(adminAccountEmailError).toString(),
            adminAccountLanguage: T.translate(adminAccountLanguageError).toString(),
            companyCurrency: T.translate(companyCurrencyError).toString(),
            companyRcsNumber: T.translate(companyRcsNumberError).toString(),
            companyEmail: T.translate(companyEmailError).toString(),
        })
        return isValid;
    }

    const hideModal = () => {
        resetForm();
        resetErrors();
        hideCreateOrEditModal();
    }

    const setFieldError = (field, errorMessage) => {
        let errors = {...initialStateInputErrors};
        errors[field] = errorMessage;
        setInputErrors(errors);
    }

    const createCompany = async companyDto => {
        setLoading(true);
        try {
            const response = await companyService.createCompany(companyDto);
            submitCompanyLogo(response.companyId);
        } catch (error) {
            const field = error.response?.data?.entityIdField;
            if (field === adminEmailField) {
                setFieldError("adminAccountEmail", error.message);
            } else if (field === adminPhoneField) {
                setFieldError("adminAccountPhone", error.message);
            } else if (field === companyPhoneField) {
                setFieldError("companyPhone", error.message);
            } else if (field === billingContactPhoneField) {
                setFieldError("companyBillingContactPhone", error.message);
            } else if (field === reportContactPhoneField) {
                setFieldError("companyReportContactPhone", error.message);
            } else {
                setApiError(error.message);
            }
            setLoading(false);
        }
    }

    const editCompany = async (id, companyDto) => {
        setLoading(true);
        try {
            await companyService.editCompany(id, companyDto);
            submitCompanyLogo(id);
            if (id === currentUser.companyId) {
                const currency = currencies.find((cur) => cur.currencyCode === companyDto.currencyCode);
                if (!!currency) {
                    updateCurrentUserCurrency({
                        ...currentUser,
                        currency: currency?.currencyCode,
                        currencySymbol: currency?.currencySymbol,
                    });
                }
            }
        } catch (error) {
            const field = error.response?.data?.entityIdField;
            if (field === companyPhoneField) {
                setFieldError("companyPhone", error.message);
            } else if (field === billingContactPhoneField) {
                setFieldError("companyBillingContactPhone", error.message);
            } else if (field === reportContactPhoneField) {
                setFieldError("companyReportContactPhone", error.message);
            } else {
                setApiError(error.message);
            }
            setLoading(false);
        }
    }

    const saveDocument = async (documentData) => {
        try {
            await documentService.deleteDocumentByEntityId(company.id);
            await documentService.createDocument(documentData);
            hideModal();
            setLoading(false);
            showToast(T.translate('alert.successTitle'), T.translate(companyReducer.isCreationMode ? 'company.successCreateCompany' : 'company.successEditCompany'), ToastClass.SUCCESS);
        } catch (error) {
            hideModal();
            setLoading(false);
            showToast(T.translate('alert.successTitle'), T.translate(companyReducer.isCreationMode ? 'company.successCreateCompany' : 'company.successEditCompany'), ToastClass.SUCCESS);
            showToast(T.translate('alert.errorTitle'), T.translate('error.company.saveLogo').toString() + error.message, ToastClass.ERROR);
        }
    }

    const submitCompanyLogo = (companyId) => {
        if (!!cropperImage && !!cropper && typeof cropper !== "undefined") {
            cropper.getCroppedCanvas({height: 180, width: 320}).toBlob(blob => {
                const documentData = new FormData();
                documentData.append('file', blob);
                documentData.append('documentType', DocumentType.COMPANY_LOGO);
                documentData.append('name', "logo");
                documentData.append('companyId', companyId);
                documentData.append('entityId', companyId);
                documentData.append('description', "");
                saveDocument(documentData);
            });
        } else {
            hideModal();
            setLoading(false);
            showToast(T.translate('alert.successTitle'), T.translate(companyReducer.isCreationMode ? 'company.successCreateCompany' : 'company.successEditCompany'), ToastClass.SUCCESS);
        }
    };

    const submitCreateCompanyForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            const companyDto = new CompanyDto(company);
            if (companyReducer.isCreationMode) {
                const adminAccountDto = new AdminAccountDto(adminAccount);
                const companyAndAdminAccountDto = new CompanyAndAdminAccountDto(companyDto, adminAccountDto);
                createCompany(companyAndAdminAccountDto);
            } else {
                editCompany(companyReducer.company.id, companyDto);
            }
        }
    }

    useEffect(() => {
        function checkImage(imageSrc, good) {
            let img = new Image();
            img.onload = good;
            img.src = imageSrc;
        }

        const getLogo = async () => {
            try {
                const logo = !companyReducer.company?.id ? null : documentService.getInlineUrlByEntityId(companyReducer.company?.id);
                checkImage(logo, () => {
                    setCompany(prevState => {
                        return {...prevState, logo};
                    });
                });
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        resetForm();
        getLogo();
    }, [companyReducer, setCompany, showToast, resetForm]);

    const languageOptions = () => {
        let options = [];
        languages.forEach((lang) => {
            options.push(
                <option key={lang.localeCode} value={lang.localeCode} selected={lang.localeCode === adminAccount.language}>
                    {lang.displayName}
                </option>
            );
        });
        return options;
    }

    const currencyOptions = () => {
        let options = [];
        currencies.forEach((currency) => {
            options.push(
                <option key={currency.currencyCode} value={currency.currencyCode} selected={currency.currencyCode === company.currency}>
                    {`${currency.displayName} (${currency.currencySymbol})`}
                </option>
            );
        });
        return options;
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitCreateCompanyForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={companyReducer.showCreateOrEditModal} onHide={hideModal}
                     title={companyReducer.isCreationMode
                         ? T.translate('company.title.createCompany')
                         : currentUser?.role === UserRole.SUPER_ADMINISTRATOR
                             ? T.translate('company.title.editCompanySuperAdmin')
                             : T.translate('company.title.editCompanyAdmin')}
                     footer={footer}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <Container>
                    <Row>
                        <Col sm={12} lg={6}>
                            <fieldset>
                                <legend>{T.translate('form.fieldset.companyInfos')}</legend>

                                <InputLabel id="companyName" className="mb-4" type="text" label={T.translate('form.label.companyName')} required={true}
                                            value={company.name} onChange={e => setCompany({...company, name: e.target.value})} error={inputErrors.companyName}/>

                                <TextareaLabel id="companyAddress" className="mb-4" label={T.translate('form.label.address')}
                                               value={company.contactAddress} onChange={e => setCompany({...company, contactAddress: e.target.value})}
                                               error={inputErrors.companyAddress}/>

                                <InputLabel id="companyPhone" className="mb-4" type="text" label={T.translate('form.label.phone')}
                                            value={company.contactPhone} onChange={e => setCompany({...company, contactPhone: e.target.value})}
                                            error={inputErrors.companyPhone}/>

                                <InputLabel id="companySendAccount" className="mb-4" type="text" label={T.translate('form.label.deliveryAccount')}
                                            value={company.deliveryAccountNumber} onChange={e => setCompany({...company, deliveryAccountNumber: e.target.value})}
                                            error={inputErrors.companyDeliveryAccount}/>

                                <InputLabel id="companyWebsite" className="mb-4" type="text" label={T.translate('form.label.website')}
                                            value={company.website} onChange={e => setCompany({...company, website: e.target.value})}/>

                                <InputLabel id="companyEmail" className="mb-4" type="text" label={T.translate('form.label.email')}
                                            value={company.contactEmail} onChange={e => setCompany({...company, contactEmail: e.target.value})}
                                            error={inputErrors.companyEmail}/>
                            </fieldset>
                        </Col>
                        <Col sm={12} lg={6}>
                            <fieldset>
                                <legend>{T.translate('form.fieldset.legalInformations')}</legend>

                                <InputLabel id="companySocialName" className="mb-4" type="text" label={T.translate('form.label.socialName')}
                                            value={company.socialName} onChange={e => setCompany({...company, socialName: e.target.value})}/>

                                <InputLabel id="companyRcsNumber" className="mb-4" type="text" label={T.translate('form.label.rcsNumber')} required={true}
                                            value={company.rcsNumber} onChange={e => setCompany({...company, rcsNumber: e.target.value})}
                                            error={inputErrors.companyRcsNumber}/>

                                <InputLabel id="companyLegalForm" className="mb-4" type="text" label={T.translate('form.label.legalForm')}
                                            value={company.legalForm} onChange={e => setCompany({...company, legalForm: e.target.value})}/>

                                <InputLabel id="companySocialCapital" className="mb-4" type="text" label={T.translate('form.label.socialCapital')}
                                            value={company.socialCapital} onChange={e => setCompany({...company, socialCapital: e.target.value})}/>

                                <InputLabel id="companyVatNumber" className="mb-4" type="text" label={T.translate('form.label.vatNumber')}
                                            value={company.vatNumber} onChange={e => setCompany({...company, vatNumber: e.target.value})}/>
                            </fieldset>
                        </Col>
                        <Col sm={12} lg={6}>
                            <fieldset>
                                <legend>{T.translate('form.fieldset.companyBillingContact')}</legend>

                                <InputLabel id="companyContactFirstname" className="mb-4" type="text" label={T.translate('form.label.firstname')}
                                            value={company.billingContactFirstname} onChange={e => setCompany({...company, billingContactFirstname: e.target.value})}
                                            error={inputErrors.companyBillingContactFirstname}/>

                                <InputLabel id="companyBillingContactLastname" className="mb-4" type="text" label={T.translate('form.label.lastname')}
                                            value={company.billingContactLastname} onChange={e => setCompany({...company, billingContactLastname: e.target.value})}
                                            error={inputErrors.companyBillingContactLastname}/>

                                <InputLabel id="companyBillingContactEmail" className="mb-4" type="text" label={T.translate('form.label.email')}
                                            value={company.billingContactEmail} onChange={e => setCompany({...company, billingContactEmail: e.target.value})}
                                            error={inputErrors.companyBillingContactEmail}/>

                                <InputLabel id="companyBillingContactPhone" className="mb-4" type="phone" label={T.translate('form.label.phone')}
                                            value={company.billingContactPhone} onChange={e => setCompany({...company, billingContactPhone: e.target.value})}
                                            error={inputErrors.companyBillingContactPhone}/>
                            </fieldset>
                        </Col>
                        <Col sm={12} lg={6}>
                            <fieldset>
                                <legend>{T.translate('form.fieldset.companyReportContact')}</legend>

                                <InputLabel id="companyReportContactFirstname" className="mb-4" type="text" label={T.translate('form.label.firstname')}
                                            value={company.reportContactFirstname} onChange={e => setCompany({...company, reportContactFirstname: e.target.value})}/>

                                <InputLabel id="companyReportContactLastname" className="mb-4" type="text" label={T.translate('form.label.lastname')}
                                            value={company.reportContactLastname} onChange={e => setCompany({...company, reportContactLastname: e.target.value})}/>

                                <InputLabel id="companyReportContactEmail" className="mb-4" type="text" label={T.translate('form.label.email')} required={true}
                                            value={company.reportContactEmail} onChange={e => setCompany({...company, reportContactEmail: e.target.value})}
                                            error={inputErrors.companyReportContactEmail}/>

                                <InputLabel id="companyReportContactPhone" className="mb-4" type="phone" label={T.translate('form.label.phone')}
                                            value={company.reportContactPhone} onChange={e => setCompany({...company, reportContactPhone: e.target.value})}
                                            error={inputErrors.companyReportContactPhone}/>
                            </fieldset>
                        </Col>
                        {companyReducer.isCreationMode && (
                            <Col sm={12} lg={6}>
                                <fieldset>
                                    <legend>{T.translate('form.fieldset.adminAccount')}</legend>

                                    <InputLabel id="adminAccountFirstname" className="mb-4" type="text" label={T.translate('form.label.firstname')} required={true}
                                                value={adminAccount.firstname} onChange={e => setAdminAccount({...adminAccount, firstname: e.target.value})}
                                                error={inputErrors.adminAccountFirstname}/>

                                    <InputLabel id="adminAccountLastname" className="mb-4" type="text" label={T.translate('form.label.lastname')} required={true}
                                                value={adminAccount.lastname} onChange={e => setAdminAccount({...adminAccount, lastname: e.target.value})}
                                                error={inputErrors.adminAccountLastname}/>

                                    <InputLabel id="adminAccountEmail" className="mb-4" type="text" label={T.translate('form.label.email')} required={true}
                                                value={adminAccount.email} onChange={e => setAdminAccount({...adminAccount, email: e.target.value})}
                                                error={inputErrors.adminAccountEmail}/>

                                    <InputLabel id="adminAccountPhone" className="mb-4" type="phone"
                                                label={T.translate('form.label.phone')}
                                                value={adminAccount.phone} onChange={e => setAdminAccount({...adminAccount, phone: e.target.value})}
                                                error={inputErrors.adminAccountPhone}/>

                                    <SelectLabel id="adminAccountLang" className="mb-4" label={T.translate('form.label.interfaceLanguage')} required={true}
                                                 options={languageOptions()} defaultValue={adminAccount.language} error={inputErrors.adminAccountLanguage}
                                                 onChange={e => setAdminAccount({...adminAccount, language: e.target.value})}/>
                                </fieldset>
                            </Col>
                        )}
                        <Col sm={12} lg={6}>
                            <fieldset>
                                <legend>{T.translate('form.fieldset.logo')}</legend>

                                <div className={`crop-image cursor-pointer ${!company.logo ? "no-logo" : ""} ${!cropperImage ? "no-image" : ""}`}
                                     onClick={() => imageCropperInputRef.current.click()}>
                                    <input ref={imageCropperInputRef} type="file" onChange={onChangeImage} accept={FileType.IMAGES} hidden={true}/>
                                    {!!cropperImage ? (
                                        <div className="img-preview"/>
                                    ) : !!company.logo && (
                                        <img src={company.logo} alt={company.name}/>
                                    )}
                                    <div className="label-click-to-choose"><span>{T.translate('form.label.image.clickToChoose')}</span></div>
                                </div>

                                <div className={`crop-container w-100 my-3 ${!cropperImage ? "d-none" : ""}`}>
                                    <Cropper
                                        zoomTo={0}
                                        preview=".img-preview"
                                        src={cropperImage}
                                        viewMode={0}
                                        guides={true}
                                        minCropBoxHeight={100}
                                        background={true}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                    />
                                </div>
                            </fieldset>
                        </Col>
                        <Col sm={12} lg={6}>
                            <fieldset>
                                <legend>{T.translate('form.fieldset.settings')}</legend>

                                <SelectLabel id="companyCurrency" className="mb-4" label={T.translate('form.label.currency')} required={true}
                                             options={currencyOptions()} defaultValue={company.currency} error={inputErrors.companyCurrency}
                                             onChange={e => setCompany({...company, currency: e.target.value})}/>

                                {currentUser?.role === UserRole.SUPER_ADMINISTRATOR && (
                                    <CheckLabel id="customTemplates" className="mb-4" label={T.translate('form.label.customTemplates')}
                                                checked={company.customTemplates} onChange={e => setCompany({...company, customTemplates: e.target.checked})}/>
                                )}
                            </fieldset>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        companyReducer: state.companyReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideCreateOrEditModal: () => dispatch(companyActions.hideCreateOrEditModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        updateCurrentUserCurrency: (user) => dispatch(authActions.authUpdateCurrentUser(user)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditCompanyModal);
