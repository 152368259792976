import {privateAPI} from './api/api';
import {ApiErrorHandler} from "./api/api-error";

const BASE_URL = '/i18n/';

const getAllLanguages = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}languages`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllCurrencies = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}currencies`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllPeriodicities = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}periodicities`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const i18nService = {
    getAllLanguages,
    getAllCurrencies,
    getAllPeriodicities,
};
