import React, {useState} from 'react';
import T from "i18n-react";
import CustomModal from "../../../components/custom-modal/custom-modal";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {Alert, Button, Form, Spinner} from "react-bootstrap-v5";
import AutocompleteLabel from "../../../components/input-label/autocomplete-label";
import {UserRole} from "../../../utils/enums/user-role";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import InputLabel from "../../../components/input-label/input-label";
import Validator from "../../../utils/validators/validator";
import {lotActions} from "../../../redux/lot/actions-lot";
import {lotService} from "../../../services/lot-service";
import {productService} from "../../../services/product-service";
import CompanyAutocompleteLabel from "../../../components/input-label/company-autocomplete-label";
import LotDto from "../../../models/dto/lot-dto";

const CreateLotModal = ({showCreateModal, currentUser, showToast, hideCreateModal}) => {

    const [isLoading, setLoading] = useState(false);

    const [company, setCompany] = useState({
        id: currentUser.companyId ?? '',
        name: ''
    });
    const [product, setProduct] = useState({id: '', name: ''});
    const [lotNumber, setLotNumber] = useState('');
    const [supplierLotNumber, setSupplierLotNumber] = useState('');
    const [manufacturingDate, setManufacturingDate] = useState('');
    const [dluo, setDluo] = useState('');

    const initialStateInputErrors = {
        company: '',
        product: '',
        lotNumber: ''
    };

    const [inputErrors, setInputErrors] = useState({...initialStateInputErrors});
    const [apiError, setApiError] = useState('');

    const resetErrors = () => {
        setInputErrors({...initialStateInputErrors});
        setApiError('');
    }

    const resetForm = () => {
        setCompany({
            id: currentUser.companyId ?? '',
            name: '',
        });
        setProduct({id: '', name: ''});
        setLotNumber('');
        setSupplierLotNumber('');
    }

    const validate = () => {
        const companyError = Validator.validateRequired(company.id);
        const productError = Validator.validateRequired(product.id);
        const lotNumberError = Validator.validateRequired(lotNumber);
        const isValid = !companyError && !productError && !lotNumberError;
        setInputErrors({
            ...inputErrors,
            company: T.translate(companyError).toString(),
            product: T.translate(productError).toString(),
            lotNumber: T.translate(lotNumberError).toString()
        })
        return isValid;
    }

    const hideModal = () => {
        resetForm();
        resetErrors();
        hideCreateModal();
    }

    const createLot = async lot => {
        setLoading(true);
        try {
            await lotService.createLot(lot);
            hideModal();
            setLoading(false);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successCreateLot'), ToastClass.SUCCESS);
        } catch (error) {
            setLoading(false);
            const field = error.response?.data?.entityIdField;
            const exceptionClass = error.response?.data?.exceptionClass;
            if (exceptionClass === "ObjectAlreadyExistsException" && field === "lotNumber") {
                setInputErrors({
                    ...inputErrors,
                    lotNumber: T.translate('error.lot.lotNumberAlreadyExists', {lotNumber: lot.lotNumber})
                });
            } else {
                setApiError(error.message);
            }
        }
    }

    const submitCreateLotForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            const lot = new LotDto({company, product, lotNumber, supplierLotNumber, manufacturingDate, dluo});
            createLot(lot);
        }
    }

    const searchProductByName = async searchText => {
        if (!company.id) return [];
        try {
            const response = await productService.getAllActivated(searchText, company.id);
            return response?.slice(0, 5).map(productElement => {
                return {
                    id: productElement.productId,
                    name: productElement.articleCode + " - " + productElement.name,
                    dluo: productElement.lifeSpan,
                }
            }) ?? [];
        } catch (error) {
            if (error.response.status !== "cancel") {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
            return [];
        }
    }

    const handleProductNameChange = e => {
        setProduct({id: null, name: e.target.value, dluo: null})
    }

    const handleProductSelected = productSelected => {
        setProduct(productSelected);
    }

    const handleCompanyChange = companySelected => {
        setCompany(companySelected);
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitCreateLotForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading && <Spinner as="span" size="sm" animation="border"/>}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={showCreateModal} onHide={hideModal} title={T.translate('lot.title.createLot')} footer={footer}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <fieldset>
                    {
                        currentUser.role === UserRole.SUPER_ADMINISTRATOR &&
                        <CompanyAutocompleteLabel id="companyId" className="mb-4" required={true} error={inputErrors.company}
                                                  defaultValue={company} onChange={handleCompanyChange}/>
                    }
                    <AutocompleteLabel id="productId" className="mb-4" label={T.translate('form.label.product')}
                                       value={product.name} onSuggestionsFetch={searchProductByName}
                                       onChange={handleProductNameChange} onSelect={handleProductSelected}
                                       required={true} error={inputErrors.product}/>

                    <InputLabel id="lotNumber" className="mb-4" type="text" label={T.translate('form.label.lot.number')} required={true}
                                value={lotNumber} onChange={e => setLotNumber(e.target.value)} error={inputErrors.lotNumber}/>

                    <InputLabel id="supplierLotNumber" className="mb-4" type="text" label={T.translate('form.label.lot.supplierLotNumber')}
                                value={supplierLotNumber} onChange={e => setSupplierLotNumber(e.target.value)}/>

                    <InputLabel id="manufacturingDate" className="mb-4" type="date" label={T.translate('form.label.lot.manufacturingDate')}
                                value={manufacturingDate} onChange={e => setManufacturingDate(e.target.value)}/>

                    <InputLabel id="dluo" className="mb-4" type="date"
                                value={dluo} onChange={e => setDluo(e.target.value)}
                                label={(
                                    <div className={!!product?.dluo ? "extended-label" : ""}>{T.translate('form.label.lot.dluo')}
                                        <div className={`small-text ${!!product?.dluo ? "" : "d-none"}`}>{product?.dluo}</div>
                                    </div>
                                )}/>

                </fieldset>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        showCreateModal: state.lotReducer.showCreateModal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideCreateModal: () => dispatch(lotActions.hideCreateModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLotModal);