import {
    ANALYSE_DELETED,
    ANALYSE_EDIT_REFERENCES_PREVIOUS_MODAL,
    ANALYSE_HIDE_CREATE_OR_EDIT_MODAL,
    ANALYSE_HIDE_EDIT_REFERENCES_MODAL,
    ANALYSE_HIDE_IMPORT_MODAL,
    ANALYSE_LABORATORY_DELETED,
    ANALYSE_SHOW_CREATE_MODAL,
    ANALYSE_SHOW_EDIT_MODAL,
    ANALYSE_SHOW_EDIT_REFERENCES_MODAL,
    ANALYSE_SHOW_IMPORT_MODAL
} from "./type";

const analyseCreate = () => {
    return {
        type: ANALYSE_SHOW_CREATE_MODAL,
    }
}

const analyseEdit = (analyseId) => {
    return {
        type: ANALYSE_SHOW_EDIT_MODAL,
        payload: analyseId
    }
}

const hideCreateOrEditModal = () => {
    return {
        type: ANALYSE_HIDE_CREATE_OR_EDIT_MODAL
    }
}

const previousEditReferencesModal = (referencesData) => {
    return {
        type: ANALYSE_EDIT_REFERENCES_PREVIOUS_MODAL,
        payload: referencesData,
    }
}

const hideEditReferencesModal = () => {
    return {
        type: ANALYSE_HIDE_EDIT_REFERENCES_MODAL
    }
}

const showImportModal = () => {
    return {
        type: ANALYSE_SHOW_IMPORT_MODAL
    }
}

const hideImportModal = () => {
    return {
        type: ANALYSE_HIDE_IMPORT_MODAL
    }
}

export const referencesEditModal = (analysisBeingCreatingOrModified, resetFormRef) => ({
    type: ANALYSE_SHOW_EDIT_REFERENCES_MODAL,
    payload: {
        analysisBeingCreatingOrModified,
        resetFormRef
    },
});

const analyseDeleted = () => {
    return {
        type: ANALYSE_DELETED
    }
}

const analyseLaboratoryDeleted = () => {
    return {
        type: ANALYSE_LABORATORY_DELETED
    }
}

export const analyseActions = {
    analyseCreate,
    analyseEdit,
    hideCreateOrEditModal,
    hideEditReferencesModal,
    previousEditReferencesModal,
    referencesEditModal,
    showImportModal,
    hideImportModal,
    analyseDeleted,
    analyseLaboratoryDeleted
};