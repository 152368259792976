import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useExpanded, useGroupBy, useSortBy, useTable} from "react-table";
import {useDrag, useDrop} from "react-dnd";
import update from "immutability-helper"
import T from "i18n-react";
import iconDrag from "../../assets/images/icons/drag.svg";
import {Image} from "react-bootstrap-v5";

const CustomTable = ({
                         columns,
                         data,
                         groupByName,
                         groupById,
                         useDragRow = false,
                         handleGroupChange,
                         handleGroupReorder,
                         leftGroupNameColumnId,
                     }) => {

    const [records, setRecords] = useState(data)

    useEffect(() => setRecords(data), [data]);

    const useControlledState = (state) => {
        return useMemo(() => { // Recompute the function if state has changed
            if (state.groupBy.length > 0) {
                return {
                    ...state,
                    hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter(
                        (d, i, all) => all.indexOf(d) === i
                    ),
                }
            }
            return state
        }, [state])
    }

    const useHooks = hooks => {
        hooks.useControlledState.push(useControlledState)
        hooks.visibleColumns.push((cols, {instance}) => {
            return instance.state.groupBy.length <= 0 ? cols : [
                ...cols.filter(col => {
                    if (leftGroupNameColumnId !== undefined) {
                        return leftGroupNameColumnId.includes(col.id)
                    }
                    return false
                }),
                {
                    id: 'expander', // On donne un id à la colonne du group by
                    // On construit la colonne
                    Header: '',
                    Cell: ({row}) => {
                        if (row.canExpand) {
                            row.toggleRowExpanded(row.id)
                            const notNullCells = row.allCells.filter(d => d.isGrouped)
                            return (<React.Fragment>
                                    {notNullCells.map(cell => {
                                        return <div>{cell.render('Cell')}</div>
                                    })}
                                </React.Fragment>
                            )
                        } else {
                            return null
                        }
                    },
                },
                ...cols.filter(col => {
                    if (leftGroupNameColumnId !== undefined) {
                        return !leftGroupNameColumnId.includes(col.id)
                    }
                    return true
                }),
            ]
        })
    };

    const getRowId = useCallback((row, index, parent) => parent ? [parent.id, index].join('.') : row.id, []);
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsExpanded} = useTable(
        {
            data: records,
            columns,
            getRowId
        },
        useGroupBy,
        useSortBy,
        useExpanded,
        useHooks
    );

    useEffect(() => {
        // Expand all rows when the component mounts
        toggleAllRowsExpanded(true);
    }, [records]);

    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = records[dragIndex]
        if (groupById) {
            let newRecords = records
            newRecords.splice(dragIndex, 1)
            newRecords.splice(hoverIndex, 0, dragRecord)
            setRecords(newRecords)
        } else {
            setRecords(
                update(records, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRecord],
                    ],
                })
            )
        }
    }

    const groupByValue = (id) => {
        let recordRow = null
        for (let element of records) {
            if (element.id === id) {
                recordRow = element
                break
            }
        }
        if (recordRow !== null) {
            let splitedGroupBy = groupById.toString().split('.')
            for (let element of splitedGroupBy) {
                recordRow = recordRow[element]
            }
            return recordRow
        } else {
            return ''
        }
    }

    const groupByValueName = (id) => {
        let recordRow = null
        for (let element of records) {
            if (element.id === id) {
                recordRow = element
                break
            }
        }
        if (recordRow !== null) {
            let splitedGroupBy = groupByName.toString().split('.')
            for (let element of splitedGroupBy) {
                recordRow = recordRow[element]
            }
            return recordRow
        } else {
            return ''
        }
    }

    const changeGroupByGroup = (sourceItem, destinationRow) => {
        const idElement = sourceItem.groupByValue
        const destinationGroupId = groupByValue(destinationRow.id)
        const destinationGroupName = groupByValueName(destinationRow.id);
        if (idElement === destinationGroupId) {
            if (handleGroupReorder) {
                handleGroupReorder(sourceItem.id, destinationRow.original.displayOrder)
            }
            return
        }
        if (handleGroupChange) {
            handleGroupChange(sourceItem.id, destinationGroupId, destinationGroupName)
        }

    }

    const getIndexById = (id) => {
        for (let i = 0; i < records.length; i++) {
            if (records[i].id === id) {
                return i
            }
        }
    }
    return (
        <>
            <table {...getTableProps()}
                   className={`table table-sm table-striped text-start ${!!groupById ? "group-by" : ""} ${!!useDragRow ? "drag-row" : ""} ${rows.length === 0 ? "mb-0" : ""}`}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {useDragRow && <th></th>}
                        {headerGroup.headers.map(column => {
                            if (column.canGroupBy && column.id === groupByName) {
                                column.toggleGroupBy(groupByName);
                            }
                            return (
                                column.id === 'expander' ? <th className="width-0"></th> :
                                    <th key={column.dataField} {...column.getHeaderProps(column.getSortByToggleProps({title: typeof column.text == 'string' ? column.text : column.text.props?.id}))}>
                                        {column.render('Header')}
                                    </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row, index) =>
                        prepareRow(row) || (
                            <Row
                                index={index}
                                row={row}
                                moveRow={moveRow}
                                changeGroupByGroup={changeGroupByGroup}
                                useDragRow={useDragRow}
                                getIndexById={getIndexById}
                                groupById={groupById}
                                groupByValue={groupByValue}
                                {...row.getRowProps()}
                            />
                        )
                )}
                </tbody>
            </table>
            {rows.length === 0 && (
                <div className="text-center m-3">{T.translate('table.empty')}</div>
            )}
        </>
    )

}

export default CustomTable;

const DND_ITEM_TYPE = 'row'

const Row = ({
                 row,
                 moveRow,
                 changeGroupByGroup,
                 useDragRow,
                 getIndexById,
                 groupById,
                 groupByValue
             }) => {
    const dropRef = useRef(null)
    const dragRef = useRef(null)
    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
            if (!groupById) {
                if (!dropRef.current) {
                    return
                }
                const dragId = item.id
                const hoverId = row.id

                const dragIndex = getIndexById(dragId)
                const hoverIndex = getIndexById(hoverId)
                // Don't replace items with themselves
                if (dragId === hoverId) {
                    return
                }
                // Don't do anything if the row is over something that isn't another row
                if (hoverIndex === undefined) {
                    return
                }
                // Determine rectangle on screen
                const hoverBoundingRect = dropRef.current.getBoundingClientRect()
                // Get vertical middle
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                // Determine mouse position
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top
                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%
                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return
                }
                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return
                }
                // Time to actually perform the action
                moveRow(dragIndex, hoverIndex)
                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                item.id = dragId
            }

        },
        drop(item, monitor) {
            if (groupById) {
                if (!dropRef.current) {
                    return
                }
                const dragId = item.id
                const dropId = row.id

                const dropIndex = getIndexById(dropId)
                // Don't replace items with themselves
                if (dragId === dropId) {
                    return
                }
                // Don't do anything if the row is dropped on something that isn't another row
                if (dropIndex === undefined) {
                    return
                }
                changeGroupByGroup(item, row)
            }
        }
    })

    const [{isDragging}, drag, preview] = useDrag({
        type: DND_ITEM_TYPE,
        item: {type: DND_ITEM_TYPE, id: row.id, groupByValue: groupById ? groupByValue(row.id) : ''},
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)

    const nbOfLeftAggregated = () => {
        let numberOfCells = 0;
        row.cells.forEach(cell => {
            if (cell.column.id !== 'expander' && cell.isAggregated && cell.row.isGrouped && cell.value !== null) {
                numberOfCells++;
            }
        })
        return numberOfCells;
    }

    return (
        <tr id={row.index} ref={dropRef} style={{opacity}} className={`${row.original?.color ?? ""}`}>
            {useDragRow && !row.isGrouped && !row.original?.disableDrag && <td ref={dragRef}><Image src={iconDrag} className="px-2"/></td>}
            {useDragRow && !row.isGrouped && !!row.original?.disableDrag && <td className="left-aggregated"/>}
            {useDragRow && row.isGrouped && !row.original?.disableDrag && <td className="left-aggregated"/>}
            {row.cells.map(cell => {
                if (cell.column.id !== 'expander' && cell.isAggregated && cell.row.isGrouped && cell.value !== null) {
                    return (
                        <td key={`row${row.index}-col${cell.column.id}`} className={"aggregated"}>
                            {cell.render('Cell')}
                        </td>
                    )
                } else if (cell.column.id === 'expander' && cell.value !== undefined) { // Si la cell est celle qui contient le nom de la catégorie, on en fait un colSpan
                    return (
                        <td key={`aggregated${row.index}`} colSpan={row.cells.length + (useDragRow ? 1 : 0) - nbOfLeftAggregated() - (useDragRow ? 1 : 0)}
                            className="aggregated">
                            {cell.render('Cell')}
                        </td>
                    )
                } else {
                    return cell.column.id === 'expander' ? <td className="width-0"></td> : cell.value === null && cell.isAggregated ? null : (
                        <td key={`row${row.index}-col${cell.column.id}`}
                            className={`row-${row.id} ${row.original.isParameter && cell.column.padding ? cell.column.padding : ''}`}>
                            {cell.column.targetForChevron && row.canExpand &&
                                <span style={{cursor: 'pointer'}}
                                      onClick={() => row.toggleRowExpanded()}>{row.isExpanded ? "▼" : "►"}&nbsp;</span>}
                            {cell.render('Cell')}
                        </td>

                    )
                }
            })}
        </tr>
    )
}