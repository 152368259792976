export default class AnalyseEntity {
    constructor(analyseData) {
        this.company = {
            id: analyseData?.company?.companyId ?? '',
            name: analyseData?.company?.name ?? ''
        };
        this.name = analyseData?.analysisInfo?.name ?? '';
        this.method = analyseData?.analysisInfo?.method ?? '';
        this.technique = analyseData?.analysisInfo?.technique ?? '';
        this.unit = analyseData?.analysisInfo?.unit ?? '';
        this.category = {
            id: analyseData?.analysisInfo?.category?.analysisCategoryId ?? '',
            name: analyseData?.analysisInfo?.category?.name ?? ''
        };
        this.mainLaboratoryId = analyseData?.analysisInfo?.mainLaboratory?.laboratoryId;
        this.laboratoryList = analyseData?.laboratoryDetails?.map(details => {
            return {
                id: details.laboratory?.laboratoryId ?? '',
                name: details.laboratory?.name ?? '',
                price: details.price,
                delay: details.delayInDays,
                quantity: details.quantityInGram,
                reference: details.reference
            }
        });
        this.laboratoryParameters = analyseData?.laboratoryParameters?.map(laboratoryParameter => {
            return {
                laboratoryId: laboratoryParameter.laboratory?.laboratoryId ?? '',
                laboratoryName: laboratoryParameter.laboratory?.name ?? '',
                parameterName: laboratoryParameter.name ?? '',
                reference: laboratoryParameter.reference ?? ''
            }
        })
    }
}