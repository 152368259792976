import React, {useCallback, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import T from 'i18n-react'
import {Button, Card} from "react-bootstrap-v5";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../../redux/alert/actions-alert";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import RemoteTable from "../../../components/table/remote-table";
import {documentService} from "../../../services/document-service";
import {documentActions} from "../../../redux/document/actions-document";
import Moment from "react-moment";
import {UserRole} from "../../../utils/enums/user-role";
import {ping} from "../../../services/api/api";
import {SortDirection} from "../../../utils/enums/sort-direction";
import DateInput from "../../../components/input-label/date-input";

const TableDocumentList = ({
                               entityId,
                               searchText,
                               className,
                               showToast,
                               documentDeleted,
                               showConfirmDialog,
                               showEditDocumentModal,
                               currentUser,
                               progress,
                               incrementProgress,
                               actualiseCountExpiredDocuments
                           }) => {

    let columns = [
        {
            dataField: T.translate('table.fields.name').toString(),
            text: T.translate('table.columns.document.name').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.description').toString(),
            text: T.translate('table.columns.description').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.document.originalFileName').toString(),
            text: T.translate('table.columns.fileName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.createdDate').toString(),
            text: T.translate('table.columns.createdDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.document.validityDate').toString(),
            text: T.translate('table.columns.document.validityDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.document.deadline').toString(),
            text: T.translate('table.columns.deadline').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString(),
            sort: false
        },
    ];

    const downloadDocRef = useRef();

    useEffect(() => {
        const getNumberExpiredDocuments = async () => {
            try {
                const response = await documentService.getIndicatorExpiredDocuments();
                actualiseCountExpiredDocuments(response.numberOfExpiredDocuments);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        getNumberExpiredDocuments();
    }, [progress]);

    const downloadDocument = async (fileUri) => {
        downloadDocRef.current.href = fileUri;
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        await ping();
        downloadDocRef.current.click();
    }

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {

        const handleEditDocument = (e, doc) => {
            showEditDocumentModal(doc)
        }

        const handleValidityDateChange = async (documentId, date, documentName) => {
            const validityDate = date ?? '';

            try {
                await documentService.editValidityDate(documentId, validityDate);
                incrementProgress();

                showToast(T.translate('alert.successTitle'), T.translate('document.successEditValidityDate', {documentName}), ToastClass.SUCCESS)
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);

            }
        }

        const deleteDocument = async doc => {
            try {
                await documentService.deleteDocument(doc.documentId);
                documentDeleted();
                showToast(T.translate('alert.successTitle'), T.translate('document.successDeleteDocument', {documentName: doc.name}), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteDocument = async (e, doc) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteDocument', {documentName: doc.name}),
                () => {
                },
                () => deleteDocument(doc))
        }

        const completeElements = allElements => {
            return allElements.map(element => {
                const dayDealine = element.daysBeforeEndValidityDate < 0 ? `+${-element.daysBeforeEndValidityDate}` : -element.daysBeforeEndValidityDate;
                const hasDateExpired = (!!element.daysBeforeEndValidityDate || element.daysBeforeEndValidityDate === 0) && element.daysBeforeEndValidityDate <= 0;
                return {
                    ...element,
                    id: element.documentId,
                    description: element.description ?? "-",
                    createdDate: <Moment format={T.translate('i18n.format.datetime').toString()}>{element.createdDate}</Moment>,
                    validityDate: <DateInput className="editable-date" id="validityDate" defaultValue={element.validityDate}
                                             onChange={date => handleValidityDateChange(element.documentId, date, element.name)}
                                             adjustWidthWithText={true} adjustWidthForEmptyText={true}
                                             isTextInRed={hasDateExpired}/>,
                    daysBeforeEndValidityDate: !element.validityDate ? "-" :
                        <span className={`${hasDateExpired ? "color-danger" : ""}`}>{T.translate('table.columns.dayDeadline', {dayNumber: dayDealine})}</span>,
                    actions: (
                        <div className="actions">
                            <div className="btn btn-primary btn-sm me-1"
                                 onClick={() => downloadDocument(documentService.getDownloadUrl(element.fileDownloadUri))}>
                                <FontAwesomeIcon icon="download"/>
                            </div>
                            {currentUser.role !== UserRole.READER && currentUser.role !== UserRole.VISITOR &&
                                <Button size="sm" className="edit me-1" onClick={e => handleEditDocument(e, element)}>
                                    <FontAwesomeIcon icon="edit"/>
                                </Button>}
                            {currentUser.role !== UserRole.READER && currentUser.role !== UserRole.VISITOR && currentUser.role !== UserRole.CONTRIBUTOR &&
                                <Button size="sm" className="delete"
                                        onClick={e => handleDeleteDocument(e, element)}>
                                    <FontAwesomeIcon icon="times"/>
                                </Button>}
                        </div>
                    )
                }
            });
        }

        const data = await documentService.getAllByEntityId(entityId, page, size, sortedOrder, sortedField, searchText);
        const elements = completeElements(data.content);
        return {
            ...data,
            elements: elements
        };
    }, [showToast, showConfirmDialog, entityId, showEditDocumentModal, documentDeleted, currentUser.role])

    return (
        <Card className={className}>
            <RemoteTable id="table-document-list" isOverflowYHidden={false} tableMinHeight={'400px'}
                         defaultSortedField={T.translate('table.fields.createdDate')}
                         defaultSortedOrder={SortDirection.DESC}
                         columns={columns} onFetchData={onFetchData} reload={progress} searchText={searchText}/>
            <a ref={downloadDocRef} href="#0" hidden={true} download>download</a>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.documentReducer.progress,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        showEditDocumentModal: (doc) => dispatch(documentActions.documentEdit(doc)),
        documentDeleted: () => dispatch(documentActions.documentDeleted()),
        incrementProgress: () => dispatch(documentActions.incrementProgress()),
        actualiseCountExpiredDocuments: (count) => dispatch(documentActions.actualiseCountExpiredDocuments(count)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableDocumentList);