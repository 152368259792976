import React from 'react';
import {Form} from "react-bootstrap-v5";
import './input-label.scss'
import DateInput from "./date-input";

const InputDateLabel = ({id, label, required, value, onChange, error, className}) => {
    return (
        <Form.Group controlId={id} className={`input-date-label row align-items-center ${className}`}>
            <Form.Label className="col col-4 text-end mb-0">{label}{!!required ? <span className="required">*</span> : ""}</Form.Label>
            <div className="col col-8">
                <DateInput value={value} onChange={onChange} error={error}/>
            </div>
        </Form.Group>
    );
}

export default InputDateLabel;

