export const LOT_SHOW_CREATE_MODAL = "LOT_SHOW_CREATE_MODAL";
export const LOT_DELETED = "LOT_DELETED";
export const LOT_HIDE_CREATE_MODAL = "LOT_HIDE_CREATE_MODAL";
export const LOT_ADD_ANALYSIS = "LOT_ADD_ANALYSIS";
export const LOT_EDIT_LOT = "LOT_EDIT_LOT";
export const LOT_UPDATE_SELECTED_ANALYSIS = "LOT_UPDATE_SELECTED_ANALYSIS";
export const LOT_INCREMENT_SELECTED_ANALYSIS = "LOT_INCREMENT_SELECTED_ANALYSIS";
export const LOT_CONTROL_DELETED = "LOT_CONTROL_DELETED";
export const LOT_CONTROL_CHANGE_DISPLAY_ORDER = "LOT_CONTROL_CHANGE_DISPLAY_ORDER";
export const LOT_CANCEL_TRANSFER = "LOT_CANCEL_TRANSFER";
export const LOT_SAVE_SEARCH_TEXT = "LOT_SAVE_SEARCH_TEXT";