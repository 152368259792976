import React, {useState} from 'react';
import CustomModal from "../../../components/custom-modal/custom-modal";
import T from "i18n-react";
import {Button, Col, Row, Spinner} from "react-bootstrap-v5";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../../redux/alert/actions-alert";
import {lotActions} from "../../../redux/lot/actions-lot";
import {connect} from "react-redux";
import {lotService} from "../../../services/lot-service";

const CancelTransferResultsModal = ({lot, show, onHide, showToast}) => {
    const [isLoading, setLoading] = useState(false);

    const handleCancel = () => {
        setLoading(false);
        onHide(false);
    }

    const handleConfirmCancelTransfer = async () => {
        try {
            setLoading(true);
            await lotService.cancelTransfer(lot.id);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successCancelTransfer'), ToastClass.SUCCESS);
            setLoading(false);
            onHide(true);
        } catch (err) {
            showToast(T.translate('alert.errorTitle'), err.message, ToastClass.ERROR);
            setLoading(false);
        }
    }

    return (
        <CustomModal show={show} title={T.translate('lot.button.cancelTransfer')} onHide={handleCancel}
                     className={`modal-transfer`} bodyClassName="py-3 px-4" centered={true}>
            <Row>
                <Col xs={10} lg={8} className="offset-1 offset-lg-2">
                    <div className="text-center mb-2">{T.translate('lot.areYouSureToCancelTransferFromLot', {lot: `${lot.sourceLotNumber} - ${lot.sourceProductName}`})}</div>
                    <div className="buttons-container d-flex justify-content-center mt-4 mb-3">
                        <Button variant="outline-secondary" onClick={handleCancel} className="me-2">
                            {T.translate('form.button.no')}
                        </Button>
                        <Button variant="success" onClick={handleConfirmCancelTransfer} className="ms-2">
                            {isLoading && <Spinner as="span" size="sm" animation="border"/>}
                            {T.translate('form.button.yes')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)), addedAnalysisToLot: () => dispatch(lotActions.addAnalysisToLot())
    }
}

export default connect(null, mapDispatchToProps)(CancelTransferResultsModal);
