import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import {Button, Col, Row} from "react-bootstrap-v5";
import OverlayLoadingOrError from "../../components/overlay-component/overlay-loading-or-error";
import {ToastClass} from "../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../redux/alert/actions-alert";
import {connect} from "react-redux";
import Header from "../../components/header/header";
import {productService} from "../../services/product-service";
import ProductEntity from "../../models/entities/product-entity";
import {UserRole} from "../../utils/enums/user-role";
import CardDocuments from "../../components/card-documents/card-documents";
import CardProductControlPlan from "./components/card-product-control-plan";
import {documentActions} from "../../redux/document/actions-document";
import {productActions} from "../../redux/product/actions-product";
import HeaderTextLabel from "../../components/header/header-text-label";
import EditableText from "../../components/editable-text/editable-text";
import {confirmDialogActions} from "../../redux/confirm-dialog/actions-confirm-dialog";
import AnalyseHistoryModal from "../../components/analyse-history-modal/analyse-history-modal";
import './product-details-page.scss'
import StatusComponent from "../../components/status-component/status-component";
import {ProductStatus, ProductStatusClassname} from "../../utils/enums/product-status";
import Moment from "react-moment";
import {StatusActiveInactive, StatusActiveInactiveClassname} from "../../utils/enums/status-active-inactive";
import CardProductInfosAndComments from "./components/card-product-infos-and-comments";
import CardProductIndicators from "./components/card-product-indicators";
import ButtonWithOptions, {ButtonOption} from "../../components/buttons/button-with-options";
import {DocumentType} from "../../utils/enums/document-type";

const ProductDetailsPage = ({showToast, currentUser, generateDocument, generateControlAndSpecs, setInitialWarning, showConfirmDialog}) => {
    const {id} = useParams();

    const [product, setProduct] = useState({});
    const [state, setState] = useState({isLoading: false, error: ''});

    const [clickCancel, setClickCancel] = useState(false)

    useEffect(() => {
        const getProduct = async () => {
            setState(prevState => {
                return {...prevState, isLoading: true};
            });
            try {
                const data = await productService.getProductById(id);
                setInitialWarning(data.controlPlanMustBeRegenerated)
                const productEntity = new ProductEntity(data);
                setProduct(productEntity)
                setState(prevState => {
                    return {...prevState, isLoading: false};
                });
            } catch (error) {
                setState(prevState => {
                    return {...prevState, isLoading: false, error: error.message};
                });
            }
        }
        getProduct();
    }, [id, setInitialWarning])

    const history = useHistory();

    const goToPreviousPage = () => {
        history.goBack();
    }

    const editProduct = async (productPatch) => {
        return await productService.editProduct(product.id, productPatch);
    }

    const handleNameChange = name => {
        document.activeElement.blur();
        setClickCancel(false)
        showConfirmDialog(
            T.translate('dialog.title.confirmEdit'),
            T.translate('dialog.confirmEditNameProduct'),
            () => {
                setClickCancel(true)
            },
            () => changeName(name),
        )
    }

    const changeName = async name => {
        try {
            setProduct({...product, name});
            await editProduct({name})
            showToast(T.translate('alert.successTitle'), T.translate('product.successEditName'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handlePrint = async e => {
        e.stopPropagation();
        try {
            setState({...state, isLoading: true});
            await productService.generateControlFile(id);
            generateDocument()
            setState({...state, isLoading: false});
            showToast(T.translate('alert.successTitle'), T.translate('product.successGenerateControlFile'), ToastClass.SUCCESS);
        } catch (error) {
            setState({...state, isLoading: false});
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleGenerateSpecsAndControlPlan = async () => {
        try {
            setState({...state, isLoading: true});
            await productService.generateFiles(id);
            setState({...state, isLoading: false, progress: state.progress + 1});
            generateDocument()
            generateControlAndSpecs()
            showToast(T.translate('alert.successTitle'), T.translate('product.successGenerateFiles'), ToastClass.SUCCESS);
        } catch (error) {
            setState({...state, isLoading: false});
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleEditComment = async comment => {
        try {
            setProduct({...product, comment});
            await productService.editProductComment(id, comment)
            showToast(T.translate('alert.successTitle'), T.translate('product.successEditComment'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleEditValue = async (name, tabNewValue, newValue) => {
        const message = name.charAt(0).toUpperCase() + name.slice(1);
        try {
            await editProduct(tabNewValue)
            updateProduct(name, newValue);
            showToast(T.translate('alert.successTitle'), T.translate('product.successEdit' + message), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const updateProduct = (name, newValue) => {
        switch (name) {
            case 'articleCode':
                const articleCode = newValue;
                return setProduct(prevState => ({...prevState, articleCode}));

            case 'activated':
                const activated = newValue;
                return setProduct(prevState => ({...prevState, activated}));

            case 'status':
                const status = newValue;
                return setProduct(prevState => ({...prevState, status}));

            case 'category':
                const category = newValue;
                return setProduct(prevState => ({...prevState, category}));

            case 'supplier':
                const supplier = newValue;
                return setProduct(prevState => ({...prevState, supplier}));

            case 'lifeDuration':
                const lifeDuration = newValue;
                return setProduct(prevState => ({...prevState, lifeDuration}));

            case 'description':
                const description = newValue;
                return setProduct(prevState => ({...prevState, description}));

            case 'updateDate':
                const updateDate = newValue;
                return setProduct(prevState => ({...prevState, updateDate}));

            default:
        }
    }

    const handleEditProductModificationStatus = async () => {
        try {
            if (product.status === ProductStatus.NOT_MODIFIABLE) {
                await productService.modify(product.id);
                updateProduct('status', ProductStatus.MODIFICATION_IN_PROGRESS);
            } else {
                await productService.validateModification(product.id);
                updateProduct('status', ProductStatus.NOT_MODIFIABLE);
                updateProduct('updateDate', new Date().toISOString());
            }
            showToast(T.translate('alert.successTitle'), T.translate('product.successEditStatus'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleEditProductActivated = activated => {
        showConfirmDialog(
            T.translate('dialog.title.confirmToggleStatus'),
            T.translate('dialog.confirmToggleProductStatus', {status: T.translate(activated ? 'status.active' : 'status.inactive')}),
            () => {
            },
            () => handleEditValue("activated", {activated}, activated)
        )
    }

    const canEdit = currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR;

    const isModificationInProgress = product.status === ProductStatus.MODIFICATION_IN_PROGRESS;

    const editOrSaveProductButton = (
        <Button variant="accent" className="px-4 me-2 mb-2 mb-sm-0" onClick={handleEditProductModificationStatus}>
            {T.translate(product.status === ProductStatus.NOT_MODIFIABLE ? 'form.button.edit' : 'form.button.confirm')}
        </Button>
    );

    const printAndGenerateSpecSheetAndControlPlanButton = (
        <ButtonWithOptions variant="primary" className="me-3 mb-2 mb-sm-0" onClick={handleGenerateSpecsAndControlPlan} title={T.translate('product.button.generateSpecsAndControlPlan')}>
            <ButtonOption className="primary p-2" title={T.translate('product.button.generateControlSheet')} onClick={handlePrint}/>
        </ButtonWithOptions>
    );

    const headerButton = canEdit && (
        <React.Fragment>
            {editOrSaveProductButton}
            {printAndGenerateSpecSheetAndControlPlanButton}
        </React.Fragment>
    );

    const headerTitle = (
        <HeaderTextLabel className="me-4" label={T.translate('product.product')} isMain={true}>
            {isModificationInProgress ? (
                <EditableText className="editable-h1" id="productName" type="text" defaultValue={product.name} onSubmit={handleNameChange} adjustWidthWithText={true} onCancelConfirmDialog={clickCancel}
                              shouldSubmitOnBlur/>
            ) : (
                <div className="header-title p-1">
                    {product.name}
                </div>
            )}
        </HeaderTextLabel>
    );

    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.product', {productName: product.name})}>
            <Header title={headerTitle} button={headerButton}>
                <HeaderTextLabel className="me-4" label={T.translate('table.columns.status')}>
                    <div className="px-2 pt-1">
                        <StatusComponent type={product.activated ? ProductStatusClassname[product.status] : StatusActiveInactiveClassname[StatusActiveInactive.INACTIVE]}
                                         onClick={!canEdit || isModificationInProgress ? null : () => handleEditProductActivated(!product.activated)}/>
                    </div>
                </HeaderTextLabel>
                <HeaderTextLabel label={T.translate('table.columns.version')}>
                    <div className="header-title p-1">
                        {!product.updateDate ? "-" : <Moment>{product.updateDate}</Moment>}
                    </div>
                </HeaderTextLabel>
            </Header>
            <Row className="gx-4 mx-1">
                <Col className="mb-4">
                    <CardProductInfosAndComments
                        className="h-100"
                        product={product}
                        onEditValue={handleEditValue}
                        onEditComment={handleEditComment}/>
                </Col>
                <Col className="indicators-container mb-4">
                    <CardProductIndicators productId={id} className="h-100"/>
                </Col>
                <Col className="mb-4">
                    <CardDocuments className="h-100" entityId={id} documentType={DocumentType.PRODUCT}/>
                </Col>
                <Col sm={12} className="mb-4">
                    <CardProductControlPlan className="h-100" product={product}/>
                </Col>
            </Row>
            <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={goToPreviousPage}/>
            <AnalyseHistoryModal/>
        </PageWithMenuTemplate>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        generateDocument: () => dispatch(documentActions.generateDocument()),
        generateControlAndSpecs: () => dispatch(productActions.generateControlAndSpecs()),
        setInitialWarning: (initialWarning) => dispatch(productActions.setInitialWarning(initialWarning))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage);
