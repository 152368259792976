import React, {useState} from 'react';
import {Button, Card, Form, Spinner} from "react-bootstrap-v5";
import T from "i18n-react";
import InputLabel from "../../../components/input-label/input-label";
import Validator from "../../../utils/validators/validator";
import {userService} from "../../../services/user-service";
import {connect} from "react-redux";
import {alertActions} from "../../../redux/alert/actions-alert";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";

const ResetPasswordForm = ({className, showToast}) => {

    const currentPasswordField = "password";
    const newPasswordField = "newPassword";
    const confirmNewPasswordField = "confirmNewPassword";

    const [isLoading, setLoading] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const initialInputErrors = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    }
    const [inputErrors, setInputErrors] = useState(initialInputErrors);

    const resetForm = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    }

    const resetErrors = () => {
        setInputErrors(initialInputErrors);
    }

    const validate = () => {
        const currentPasswordError = Validator.validateRequired(currentPassword);
        const newPasswordError = Validator.validateRequiredPassword(newPassword);
        const confirmNewPasswordError = Validator.validateConfirmPassword(newPassword, confirmNewPassword);
        const isValid = !currentPasswordError && !newPasswordError && !confirmNewPasswordError;
        setInputErrors({
            ...inputErrors,
            currentPassword: T.translate(currentPasswordError),
            newPassword: T.translate(newPasswordError),
            confirmNewPassword: T.translate(confirmNewPasswordError)
        });
        return isValid;
    }

    const changePassword = async () => {
        try {
            setLoading(true);
            await userService.changeCurrentUserPassword(currentPassword, newPassword, confirmNewPassword);
            setLoading(false);
            resetForm();
            resetErrors();
            showToast(T.translate('alert.successTitle'), T.translate('changePassword.success'), ToastClass.SUCCESS);
        } catch (error) {
            const field = error.response?.data?.entityIdField;
            if (field === currentPasswordField) {
                setInputErrors({...initialInputErrors, currentPassword: error.message});
            } else if (field === newPasswordField) {
                setInputErrors({...initialInputErrors, newPassword: error.message});
            } else if (field === confirmNewPasswordField) {
                setInputErrors({...initialInputErrors, confirmNewPassword: error.message});
            } else {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
            setLoading(false);
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            changePassword();
        }
    }

    const handleCancel = () => {
        resetForm();
        resetErrors();
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Card className={className}>
                <Card.Header>
                    <Card.Title>{T.translate('profile.title.resetPassword')}</Card.Title>
                </Card.Header>
                <Card.Body className="position-relative px-3">
                    <fieldset>
                        <InputLabel id="currentPassword" className="mb-4" type="password"
                                    label={T.translate('form.label.currentPassword')} required={true}
                                    value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} error={inputErrors.currentPassword}/>
                        <InputLabel id="newPassword" className="mb-4" type="password"
                                    label={T.translate('form.label.newPassword')} required={true}
                                    value={newPassword} onChange={e => setNewPassword(e.target.value)} error={inputErrors.newPassword}/>
                        <InputLabel id="confirmNewPassword" className="mb-4" type="password"
                                    label={T.translate('form.label.confirmNewPassword')} required={true}
                                    value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} error={inputErrors.confirmNewPassword}/>
                    </fieldset>
                </Card.Body>
                <Card.Footer className="text-end pb-3 pt-2">
                    <Button type="button" variant="outline-secondary" size="sm" onClick={handleCancel} disabled={isLoading}>
                        {T.translate('form.button.cancel')}
                    </Button>
                    <Button type="submit" variant="primary" size="sm" className="ms-3" disabled={isLoading}>
                        {isLoading && <Spinner animation="border" size="sm"/>}
                        {T.translate('form.button.confirm')}
                    </Button>
                </Card.Footer>
            </Card>
        </Form>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(null, mapDispatchToProps)(ResetPasswordForm);