import {AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS, AUTH_UPDATE_CURRENT_USER} from "./type";

const LOCALSTORAGE_CURRENT_USER = 'currentUser';

const currentUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENT_USER));

const initialStateAuth = {
    isLoading: false,
    isAuth: !!currentUser,
    currentUser: currentUser,
    error: ''
}

const authReducer = (state = initialStateAuth, action) => {
    switch (action.type) {
        case AUTH_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case AUTH_SUCCESS:
            const user = action.payload;
            localStorage.setItem(LOCALSTORAGE_CURRENT_USER, JSON.stringify(user));
            return {
                ...state,
                isLoading: false,
                isAuth: true,
                currentUser: user,
                error: ''
            }
        case AUTH_ERROR:
            return {
                ...state,
                isLoading: false,
                isAuth: false,
                currentUser: null,
                error: action.payload
            }
        case AUTH_LOGOUT:
            localStorage.removeItem(LOCALSTORAGE_CURRENT_USER);
            return {
                ...state,
                isLoading: false,
                isAuth: false,
                currentUser: null,
                error: ''
            }
        case AUTH_UPDATE_CURRENT_USER:
            const userUpdated = {
                ...state.currentUser,
                ...action.payload
            };
            localStorage.setItem(LOCALSTORAGE_CURRENT_USER, JSON.stringify(userUpdated))
            return {
                ...state,
                currentUser: userUpdated
            }
        default:
            return state;
    }
}

export default authReducer;