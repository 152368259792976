import React, {useState} from "react";
import T from "i18n-react";
import {authService} from "../../services/auth-service";
import {RequestState} from "../../utils/enums/request-state";
import {useHistory} from "react-router-dom";
import {Routes} from "../../router/router-constants";
import {alertActions} from "../../redux/alert/actions-alert";
import {ToastClass} from "../../components/toast-alert/toast-alert-class";
import {connect} from "react-redux";
import {Alert, Button, Form, FormCheck, FormControl, FormGroup, Spinner} from "react-bootstrap-v5";
import PageFormTemplate from "../../components/template-pages/page-form-template";
import Validator from "../../utils/validators/validator";
import {InputMaxLength} from "../../utils/enums/input-max-length";
import I18nController from "../../utils/i18n/i18n-controller";

import pdfCGU_fr from '../../assets/pdf/fr/cgu.pdf'
import pdfCGU_en from '../../assets/pdf/en/cgu.pdf'
import pdfLicence_fr from '../../assets/pdf/fr/contrat_licence.pdf'
import pdfLicence_en from '../../assets/pdf/en/contrat_licence.pdf'

const ChangePasswordPage = props => {
    const token = props.match.params.token;
    const onlyCGU = props.match.params?.onlyCGU === "true";
    const {isCreation, showToast} = props;

    const history = useHistory();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [cguAccepted, setCguAccepted] = useState(false);
    const [licenceAccepted, setLicenceAccepted] = useState(false);

    const [inputErrors, setInputErrors] = useState({
        hasErrors: false,
        password: '',
        confirmPassword: '',
        licence: '',
        cgu: ''
    });

    const [requestState, setRequestState] = useState({
        state: RequestState.INITIAL,
        message: ''
    });

    const resetErrors = () => {
        setInputErrors({
            hasErrors: false,
            password: '',
            confirmPassword: '',
            licence: '',
            cgu: ''
        });
    }

    const validate = () => {
        const passwordError = Validator.validateRequiredPassword(password);
        const confirmPasswordError = Validator.validateConfirmPassword(password, confirmPassword);
        const licenceError = isCreation && !onlyCGU ? Validator.validateChecked(licenceAccepted) : '';
        const cguError = isCreation ? Validator.validateChecked(cguAccepted) : '';
        const isValid = !passwordError && !confirmPasswordError && !licenceError && !cguError;
        setInputErrors({
            hasErrors: !isValid,
            password: T.translate(passwordError).toString(),
            confirmPassword: T.translate(confirmPasswordError).toString(),
            licence: T.translate(licenceError).toString(),
            cgu: T.translate(cguError).toString()
        })
        return isValid;
    }

    const onSubmit = async () => {
        setRequestState({
            state: RequestState.LOADING,
            message: ''
        });
        try {
            await authService.changePassword(token, onlyCGU, password, confirmPassword);
            showToast(T.translate("alert.successTitle"), T.translate(!!isCreation ? "changePassword.successCreate" : "changePassword.success", ToastClass.SUCCESS))
            history.push(Routes.LOGIN_PAGE);
        } catch (error) {
            setRequestState({
                state: RequestState.ERROR,
                message: error.message
            });
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        resetErrors();
        if (validate()) {
            onSubmit();
        }
    }

    const languageCode = I18nController.getInstance().getLanguageCode();

    const getLicenceLink = () => {
        switch (languageCode) {
            case 'en':
                return pdfLicence_en;
            case 'fr':
            default:
                return pdfLicence_fr;
        }
    }

    const getCGULink = () => {
        switch (languageCode) {
            case 'en':
                return pdfCGU_en;
            case 'fr':
            default:
                return pdfCGU_fr;
        }
    }

    const licenceLink = <a href={getLicenceLink()} rel="noreferrer" target="_blank">{T.translate('auth.licenceContract')}</a>;
    const cguLink = <a href={getCGULink()} rel="noreferrer" target="_blank">{T.translate('auth.cgu')}</a>;

    return (
        <PageFormTemplate onSubmit={handleSubmit} pageTitle={T.translate(!!isCreation ? 'pageTitle.createPassword' : 'pageTitle.changePassword')}>
            <h1 className="mb-3">{T.translate(!!isCreation ? "auth.titleCreatePassword" : "auth.titleResetPassword")}</h1>
            <Alert variant={requestState.state === RequestState.ERROR ? "danger" : "success"}
                   hidden={requestState.state !== RequestState.ERROR && requestState.state !== RequestState.SUCCESS}>
                <Alert.Heading>
                    {T.translate(requestState.state === RequestState.ERROR ? "alert.errorTitle" : "alert.successTitle")}
                </Alert.Heading>
                {requestState.message}
            </Alert>
            <Form.Group controlId="password" className="form-floating mt-4 mb-3">
                <Form.Control type='password' placeholder="xxx" value={password} onChange={e => setPassword(e.target.value)}
                              isInvalid={!!inputErrors.password} maxLength={InputMaxLength.STANDARD_TEXT}/>
                <Form.Label>{T.translate("form.label.password")}</Form.Label>
                <Form.Control.Feedback type='invalid'>
                    {inputErrors.password}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="confirmPassword" className="form-floating mb-3">
                <Form.Control type='password' placeholder="xxx" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}
                              isInvalid={!!inputErrors.confirmPassword} maxLength={InputMaxLength.STANDARD_TEXT}/>
                <Form.Label>{T.translate("form.label.confirmPassword")}</Form.Label>
                <Form.Control.Feedback type='invalid'>
                    {inputErrors.confirmPassword}
                </Form.Control.Feedback>
            </Form.Group>
            {!!isCreation && (
                <React.Fragment>
                    {!onlyCGU && (
                        <FormGroup controlId="licence" className="text-start mb-2">
                            <FormCheck label={T.translate('auth.iAgree', {data: licenceLink})} isInvalid={!!inputErrors.licence}
                                       defaultValue={licenceAccepted} onChange={e => setLicenceAccepted(e.target.checked)}/>
                            <FormControl.Feedback type="invalid" className={!!inputErrors.licence ? "d-block small-text" : ""}>
                                {inputErrors.licence}
                            </FormControl.Feedback>
                        </FormGroup>
                    )}
                    <FormGroup controlId="cgu" className="text-start mb-2">
                        <FormCheck label={T.translate('auth.iAgree', {data: cguLink})} isInvalid={!!inputErrors.cgu}
                                   defaultValue={cguAccepted} onChange={e => setCguAccepted(e.target.checked)}/>
                        <FormControl.Feedback type="invalid" className={!!inputErrors.cgu ? "d-block small-text" : ""}>
                            {inputErrors.cgu}
                        </FormControl.Feedback>
                    </FormGroup>
                </React.Fragment>
            )}
            <Button variant="primary" type="submit" className="w-100 p-2 my-2" disabled={requestState.state === RequestState.LOADING}>
                {requestState.state === RequestState.LOADING ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate("form.button.confirm")}
            </Button>
        </PageFormTemplate>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(null, mapDispatchToProps)(ChangePasswordPage);