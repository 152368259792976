import {ApiErrorHandler} from "./api/api-error";
import {onceGet, privateAPI} from "./api/api";

const BASE_URL = '/analyses/';

const config = {
    headers: {
        'accept': 'application/json',
        'content-type': 'multipart/form-data'
    }
}

// FIXME la page des analyses montre une erreur data is undefined si on tape plein de caractères à la suite
const getAll = async (page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'analysisInfo.name', searchText = '', companyId = null, sortByCategory = true) => {
    try {
        const response = await onceGet(`${BASE_URL}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'analysisInfo.name'}&searchText=${searchText}${!!companyId ? "&companyId=" + companyId : ""}&sortByCategory=${sortByCategory}`, 'getAll')
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllWithoutLaboratory = async (laboratoryId, page = 1, size = 10, searchText = '') => {
    try {
        const response = await onceGet(`${BASE_URL}without-laboratory/${laboratoryId}?page=${page}&size=${size}&searchText=${searchText}`, 'getAllWithoutLaboratory');
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}


const getAllInProgress = async (page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'lot.lotNumber', searchText = '') => {
    try {
        const response = await privateAPI.get(`/lots/analysis/in-progress/?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'lot.lotNumber'}&searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}


const getAnalyse = async (analyseId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${analyseId}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAnalysisByLaboratoryId = async (id, page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'analysisInfo.name', searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}laboratory/${id}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'analysisInfo.name'}&searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createAnalyse = async (analyse) => {
    try {
        const response = await privateAPI.post(BASE_URL, analyse);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const addFormatToImportResults = async (id, formData) => {
    try {
        const response = await privateAPI.post(`${BASE_URL}laboratory/${id}/import-results-template`, formData, config);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const importResults = async (id, formData) => {
    try {
        const response = await privateAPI.post(`${BASE_URL}laboratory/${id}/import-results`, formData, config);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteAnalysis = async (id) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${id}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteAnalysisLaboratory = async (analysisId, laboratoryId) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${analysisId}/laboratory/${laboratoryId}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editAnalyse = async (analyseId, analyse) => {
    try {
        const response = await privateAPI.put(`${BASE_URL}${analyseId}`, analyse);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const analyseService = {
    getAll,
    getAllWithoutLaboratory,
    getAllInProgress,
    deleteAnalysis,
    getAnalysisByLaboratoryId,
    deleteAnalysisLaboratory,
    getAnalyse,
    createAnalyse,
    editAnalyse,
    addFormatToImportResults,
    importResults
};
