import React from 'react';
import {Form, FormSelect} from "react-bootstrap-v5";
import './input-label.scss'

const SelectLabel = ({id, className, label, required, defaultValue, onChange, options, error, onSelect, onClick}) => {
    return (
        <Form.Group controlId={id} className={`select-label row align-items-center ${className}`}>
            <Form.Label className={`col col-4 text-end mb-0 ${!label ? "d-none" : ""}`}>
                {label}{!!required && <span className="required">*</span>}
            </Form.Label>
            <div className={`col ${!label ? "col-12" : "col-8"}`}>
                <FormSelect onChange={onChange} defaultValue={defaultValue} isInvalid={!!error} onSelect={onSelect} onClick={onClick}>
                    {options}
                </FormSelect>
                <Form.Control.Feedback type='invalid'>
                    {error}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default SelectLabel;

