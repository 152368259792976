import React from 'react';
import {Form, FormLabel} from "react-bootstrap-v5";
import {FileType} from "./file-type";
import InputFile from "./input-file";
import './input-label.scss'

const InputFileLabel = ({id, className, label, required, onChange, error, defaultFileName, readOnly, accept = FileType.ALL}) => {

    return (
        <Form.Group controlId={id} className={`input-file-label row align-items-center ${className}`}>
            <FormLabel className="col col-4 text-end mb-0">{label}{!!required ? <span className="required">*</span> : ""}</FormLabel>
            <div className="col col-8">
                <InputFile onChange={onChange} error={error} accept={accept} defaultFileName={defaultFileName} readOnly={readOnly}/>
                <Form.Control.Feedback type="invalid" className={!!error ? "d-block" : "d-none"}>
                    {error}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default InputFileLabel;