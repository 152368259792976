import React, {useEffect, useState} from 'react';
import CustomModal from "../custom-modal/custom-modal";
import {connect} from "react-redux";
import {analyseHistoryActions} from "../../redux/analyse-history/actions-analyse-history";
import {Tab, Tabs} from "react-bootstrap-v5";
import T from "i18n-react";
import AnalyseHistoryTable from "./analyse-history-table";
import './analyse-history-modal.scss';
import {CompliantType, CompliantTypeClassName} from "../../utils/enums/compliant-type";
import Moment from "react-moment";
import {productService} from "../../services/product-service";
import {lotService} from "../../services/lot-service";
import OverlayLoadingOrError from "../overlay-component/overlay-loading-or-error";

const AnalyseHistoryModal = ({show, hideModal, analyseId, parameterName, productId, lotId}) => {

    const [state, setState] = useState({isLoading: false, error: ''});
    const [data, setData] = useState({
        productName: '',
        analysisName: '',
        parameterName: '',
        periodicity: '',
        results: [],
    });

    useEffect(() => {
        const completeElement = (element, index) => {
            return {
                ...element,
                id: `analysis-history-${index}`,
                color: !!element.compliance && element.compliance !== CompliantType.NONE ? CompliantTypeClassName[element.compliance] : "",
                lotCreationDate: !!element.lotCreationDate ? <Moment>{element.lotCreationDate}</Moment> : "-",
                lastResultModificationDate: !!element.lastResultModificationDate ? <Moment>{element.lastResultModificationDate}</Moment> : "-",
                source: !!element.sourceLot?.lotNumber ? T.translate('lot.label.lotWithLotNumber', {lotNumber: element.sourceLot?.lotNumber}) : element.laboratoryName,
                orderNumber: element.orderNumber ?? "-",
            }
        }

        const getAnalyseHistory = async () => {
            try {
                setState({isLoading: true, error: ''});
                let response;
                if (!!parameterName) {
                    let encodedParameterNameBase64 = encodeBase64(parameterName);
                    response = !!productId
                        ? await productService.getParameterHistory(analyseId, encodedParameterNameBase64, productId)
                        : await lotService.getParameterHistory(analyseId, encodedParameterNameBase64, lotId);
                } else {
                    response = !!productId
                        ? await productService.getAnalyseHistory(analyseId, productId)
                        : await lotService.getAnalyseHistory(analyseId, lotId);
                }
                setState({isLoading: false, error: ''});
                const elements = response.results.map((element, index) => completeElement(element, index));
                setData({
                    ...response,
                    results: elements,
                    parameterName: parameterName,
                });
            } catch (error) {
                setState({isLoading: false, error: error.message});
            }
        }

        if (!!analyseId && (!!productId || !!lotId)) {
            getAnalyseHistory();
        }
    }, [analyseId, productId, lotId]);

    const handleHideModal = () => {
        setState({isLoading: false, error: ''});
        setData({
            productName: '',
            analysisName: '',
            periodicity: '',
            results: [],
        });
        hideModal();
    }

    return (
        <CustomModal show={show} onHide={handleHideModal} bodyClassName="px-0 pb-0" centered={true}>
            <Tabs id="analysis-history-tab" className="analysis-history-tab pt-2 px-4" defaultActiveKey="table">
                <Tab eventKey="table" title={T.translate('analyseHistory.tabs.results')}>
                    <AnalyseHistoryTable data={data}/>
                </Tab>
            </Tabs>
            <OverlayLoadingOrError isLoading={state.isLoading} error={state.error}/>
        </CustomModal>
    );
}

function encodeBase64(stringToEncode) {
    const Buffer = require("buffer").Buffer;
    return new Buffer(stringToEncode).toString("base64");
}

const mapStateToProps = state => {
    return {
        show: state.analyseHistoryReducer.showHistoryModal,
        analyseId: state.analyseHistoryReducer.analyseId,
        productId: state.analyseHistoryReducer.productId,
        lotId: state.analyseHistoryReducer.lotId,
        parameterName: state.analyseHistoryReducer.parameterName,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(analyseHistoryActions.hideAnalyseHistoryModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseHistoryModal);